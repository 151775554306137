var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CategoryApi } from '@common/api/category';
import { useAsyncEffect } from 'ahooks';
import { useSetRecoilState } from 'recoil';
import { pageHomeCategory } from '../store/category';
import { useSSO } from '../store/common';
export const useCategory = () => {
    const { settled } = useSSO();
    const setCategoryList = useSetRecoilState(pageHomeCategory);
    /**
     * 用户登录后,更新展示分类信息
     */
    useAsyncEffect(() => __awaiter(void 0, void 0, void 0, function* () {
        if (settled) {
            const res = yield CategoryApi.getList();
            setCategoryList((val) => (Object.assign(Object.assign({}, val), { categoryList: res[0].childTree })));
        }
    }), [settled]);
};
