var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { MemberApi } from '@common/api/member';
import { ACCESS_TOKEN_KEY, MEMBER_STORE_URL, USER_STORAGE_KEY } from '@common/constants/common';
import { global } from '@common/constants/env';
import { KEYCLOAK_LANGUAGE } from '@common/constants/language';
import axios from 'axios';
import { remove } from 'lodash-es';
import { i18n } from 'next-i18next';
import { createUnique } from './common';
import { isGuest } from './is-guest';
import { getToken, isInRn, login } from './rn-utils';
function removeHashParam(key) {
    const currentUrl = window.location.href;
    const urlParts = currentUrl.split('#');
    const hashPart = urlParts.length > 1 ? urlParts[1] : '';
    // 分割并过滤哈希片段中的参数
    const params = new URLSearchParams(hashPart);
    params.delete(key);
    // 重新构建哈希片段
    let newHash = params.toString();
    if (newHash) {
        newHash = `#${newHash}`;
    }
    // 替换整个 URL 或仅替换哈希部分
    const baseUrl = urlParts[0];
    window.history.replaceState(window.history.state, '', baseUrl + newHash);
}
class Auth {
    constructor() {
        this._inited = false;
        this._data = undefined;
        this._keycloak = null;
        this._initUserSession = createUnique();
        this.initWithKC = (kc) => __awaiter(this, void 0, void 0, function* () {
            var _a;
            this._keycloak = kc;
            this._keycloak.onAuthRefreshSuccess = () => {
                this.updateUserSession();
            };
            this._keycloak.onAuthError = (e) => {
                console.log('keycloak auth error', e);
            };
            if ((_a = this._keycloak) === null || _a === void 0 ? void 0 : _a.authenticated) {
                removeHashParam('iss');
                yield this.updateUserSession();
                this._startTokenRefreshTimer();
            }
            else if (!isGuest()) {
                /**
                 * 此判断针对多页签情况下，用户在一个页签中登出，
                 * 另一个页签中的用户信息未同步的情况
                 */
                global.accessToken = undefined;
                global.userSession = this._initUserSession;
                yield axios.get(`/api/login?t=${new Date().getTime()}`, {
                    headers: {
                        [ACCESS_TOKEN_KEY]: '',
                        [USER_STORAGE_KEY]: this._initUserSession
                    }
                });
            }
            this.emit('inited', this._data);
        });
        this._timer = null;
        this._callbacks = {
            inited: undefined,
            login: undefined,
            logout: undefined
        };
    }
    get keycloak() {
        return this._keycloak;
    }
    get data() {
        return this._data;
    }
    get inited() {
        return this._inited;
    }
    init() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this._inited) {
                return;
            }
            this._inited = true;
            if (isInRn()) {
                yield this.updateUserSession();
                return;
            }
            const { KC } = window;
            if (KC) {
                this.initWithKC(KC);
            }
            else {
                window.KeycloakInitCallback = this.initWithKC.bind(this);
            }
        });
    }
    /**
     * Starts the token refresh timer.
     */
    _startTokenRefreshTimer() {
        var _a, _b, _c, _d, _e;
        if (this._keycloak && ((_b = (_a = this._keycloak) === null || _a === void 0 ? void 0 : _a.tokenParsed) === null || _b === void 0 ? void 0 : _b.exp) && ((_d = (_c = this._keycloak) === null || _c === void 0 ? void 0 : _c.tokenParsed) === null || _d === void 0 ? void 0 : _d.iat)) {
            // 获取当前Access Token的剩余有效时间
            // eslint-disable-next-line no-unsafe-optional-chaining
            const { exp, iat } = (_e = this._keycloak) === null || _e === void 0 ? void 0 : _e.tokenParsed;
            const tokenExpiration = (exp - iat) * 1000;
            const timeToRefresh = tokenExpiration / 2; // 提前60秒刷新Token
            // 设置定时器，定时刷新Token
            this._timer = +setInterval(() => __awaiter(this, void 0, void 0, function* () {
                yield this._refreshToken();
                clearInterval(this._timer);
                this._startTokenRefreshTimer();
            }), timeToRefresh);
        }
    }
    _refreshToken() {
        return __awaiter(this, void 0, void 0, function* () {
            return (this._keycloak &&
                this._keycloak.updateToken(0).catch(() => {
                    this.login();
                }));
        });
    }
    /**
     * 新 token 换取 userSession
     */
    updateUserSession() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            let userSession = '';
            let firstFlag = false;
            let accessToken = '';
            if (isInRn()) {
                const res = getToken();
                accessToken = (res === null || res === void 0 ? void 0 : res.accessToken) || '';
                userSession = (res === null || res === void 0 ? void 0 : res.userSession) || '';
                firstFlag = res === null || res === void 0 ? void 0 : res.firstFlag;
            }
            else {
                accessToken = (_a = this._keycloak) === null || _a === void 0 ? void 0 : _a.token;
                const res = yield MemberApi.loginByPwd({
                    // 推广参数，记录会员来源
                    extend: (_b = global.extend) !== null && _b !== void 0 ? _b : undefined
                }, {
                    headers: {
                        accessToken
                    }
                });
                userSession = res.userSession;
                firstFlag = res.firstFlag;
            }
            this._data = { accessToken, userSession, firstFlag };
            yield axios.get(`/api/login?t=${new Date().getTime()}`, {
                headers: {
                    [ACCESS_TOKEN_KEY]: accessToken || '',
                    [USER_STORAGE_KEY]: userSession || ''
                }
            });
            global.accessToken = accessToken;
            global.userSession = userSession;
            this.emit('login', this._data);
        });
    }
    login(options) {
        if (isInRn()) {
            const res = login(options);
            this.emit('login', this._data);
            return res;
        }
        const createRedirectUri = () => {
            const { href } = window.location;
            if (href.indexOf('from=kc') > -1) {
                return href;
            }
            if (href.indexOf('?') > -1) {
                return `${href}&from=kc`;
            }
            return `${href}?from=kc`;
        };
        const doLogin = () => {
            const opts = Object.assign({ redirectUri: createRedirectUri(), locale: KEYCLOAK_LANGUAGE[i18n === null || i18n === void 0 ? void 0 : i18n.language] }, options);
            return (this._keycloak &&
                this._keycloak.login(opts).then(() => {
                    removeHashParam('iss');
                    this.updateUserSession();
                }));
        };
        if (this._keycloak) {
            doLogin();
        }
        else {
            this.addListener('inited', () => doLogin);
        }
    }
    logout(options) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            yield MemberApi.logout();
            yield axios.get(`/api/login?t=${new Date().getTime()}`, {
                headers: {
                    [ACCESS_TOKEN_KEY]: '',
                    [USER_STORAGE_KEY]: createUnique()
                }
            });
            const isElite = window.location.pathname.includes(`/${MEMBER_STORE_URL}`);
            return (_a = (this._keycloak &&
                this._keycloak.logout(Object.assign(Object.assign({}, options), { locale: 'hk', redirectUri: `${window.location.origin}/${isElite ? MEMBER_STORE_URL : ''}` })))) === null || _a === void 0 ? void 0 : _a.then(() => {
                this.emit('logout');
            });
        });
    }
    updatePassword() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.login({ action: 'UPDATE_PASSWORD' });
        });
    }
    register(options) {
        return this._keycloak && this._keycloak.register(options);
    }
    emit(event, data) {
        if (this._callbacks[event]) {
            this._callbacks[event].forEach((cb) => cb(data));
            this._callbacks[event] = undefined;
        }
    }
    addListener(event, callback) {
        if (this._callbacks[event]) {
            this._callbacks[event].push(callback);
        }
        else {
            this._callbacks[event] = [callback];
        }
    }
    removeListener(event, callback) {
        if (this._callbacks[event]) {
            remove(this._callbacks[event], (i) => i === callback);
        }
    }
}
export const globalAuth = new Auth();
